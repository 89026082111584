<template>
  <div class="main">
    <div class="page-box">
      <div class="page-title">医保购买或台账</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breadcrumb-active"
          ><i class="fa fa-dashboard"></i>工作台</el-breadcrumb-item
        >
        <el-breadcrumb-item>医保购买或台账</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="card-box">
      <div class="screen-box">
        <div class="screen-input-box">
          <div class="title-box">项目部:</div>
          <el-select
            v-model="projectID"
            placeholder="请选择项目部"
            filterable
            clearable
            class="card-select"
          >
            <el-option
              v-for="item in projectList"
              :key="item.ProjectID"
              :label="item.ProjectName"
              :value="item.ProjectID"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">用工单位:</div>
          <el-select
            v-model="companyID"
            placeholder="请选择用工单位"
            filterable
            clearable
            class="card-select"
          >
            <el-option
              v-for="item in companyList"
              :key="item.ID"
              :label="item.CompanyCHS"
              :value="item.ID"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">SPONSOR:</div>
          <el-select
            v-model="sponsor"
            placeholder="请选择SPONSOR"
            filterable
            clearable
            class="card-select"
          >
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">员工编码:</div>
          <el-input v-model="userCode" clearable placeholder="请输入员工编码" />
        </div>
        <div class="screen-input-box">
          <div class="title-box">暂住证号:</div>
          <el-input v-model="iqamaCode" clearable placeholder="暂住证号" />
        </div>
        <div class="screen-input-box">
          <div class="title-box">海关入境号:</div>
          <el-input v-model="borderNumber" clearable placeholder="海关入境号" />
        </div>
        <div class="screen-input-box">
          <div class="title-box">申请日期:</div>
          <el-date-picker
            v-model="evacuationDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
        <button class="btn-pre" @click="getEmployeeMedicalInsuranceRecords(1)">
          <i class="fa fa-search"></i>查询
        </button>
        <button class="btn-pre" @click="dialogVisible2 = true">
          <i class="fa fa-upload"></i>导入
        </button>
        <button @click="exportEmployeeMedicalInsuranceRecords" class="btn-pre">
          <i class="fa fa-download"></i> 导出
        </button>
      </div>
      <div class="table-box">
        <el-table
          ref="doLayout"
          class="doLayout"
          :data="tableData"
          height="500"
          border
          tooltip-effect="light"
          :header-cell-style="{ color: '#f5f5f5', background: '#23527c' }"
          row-key="KeyID"
          lazy
          :load="load"
          :tree-props="{ children: 'Children', hasChildren: 'HasChildren' }"
          :empty-text="loading ? '加载中...' : '暂无数据'"
        >
          <el-table-column label="#" width="55">
            <template slot-scope="scope">
              <el-checkbox></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column prop="EmployeeCode" label="员工编码" width="80" />
          <el-table-column prop="NameinEnglish" label="拼音姓名" width="80" />
          <el-table-column prop="Gender" label="性别" width="80">
            <template slot-scope="scope">{{
              scope.row.Gender | labelFormater(options3)
            }}</template>
          </el-table-column>
          <el-table-column prop="NationalityName" label="国籍" width="100">
          </el-table-column>
          <el-table-column prop="SPONSOR" label="SPONSOR" width="100">
            <template slot-scope="scope">{{
              scope.row.SPONSOR | labelFormater(options2)
            }}</template>
          </el-table-column>
          <el-table-column prop="BorderNumber" label="海关入境号" width="100">
          </el-table-column>
          <el-table-column prop="IQAMAID" label="暂住证号" width="100">
          </el-table-column>
          <el-table-column prop="IQAMAValidDate" label="暂住证日期" width="100">
            <template slot-scope="scope">{{
              scope.row.IQAMAValidDate | timeFormater
            }}</template>
          </el-table-column>
          <el-table-column prop="CreateDate" label=" 申请日期" width="100">
            <template slot-scope="scope">{{
              scope.row.CreateDate | timeFormater
            }}</template>
          </el-table-column>
          <el-table-column prop="ClassName" label="医保等级" width="90">
          </el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <i
                class="fa fa-edit"
                v-if="powers.update"
                @click="edit(scope.row)"
              ></i>
              <i
                class="fa fa-trash"
                v-if="powers.delete && !scope.row.FromApplication"
                @click="
                  deleteEmployeeMedicalInsuranceRecordsChildren(scope.row)
                "
              ></i>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          @size-change="getEmployeeMedicalInsuranceRecords(1)"
          @current-change="getEmployeeMedicalInsuranceRecords"
          :current-page.sync="page"
          :page-size.sync="size"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="医保购买或台账导入"
      :visible.sync="dialogVisible2"
      width="600px"
      :before-close="handleClose2"
      top="50px"
      class="dialog"
    >
      <div class="text-box">
        <div class="text-title">模板文件</div>
        <div class="text-name" @click="getDownloadUrl(downloadUrl)">
          医保购买或台账导入模板
        </div>
      </div>
      <div class="upload-box2">
        <div class="left">
          <div v-if="name2" class="name">
            <svg-icon class="icon" icon-class="glyphicon-file"></svg-icon>
            {{ name2 }}
          </div>
        </div>
        <el-upload
          action="#"
          class="card-upload"
          :auto-upload="true"
          :http-request="uploadAttachment"
          ref="uploadRef"
        >
          <button class="btn-up">
            <svg-icon class="icon" icon-class="file"></svg-icon>选择 ...
          </button>
        </el-upload>
      </div>
      <div class="dialog-footer2">
        <el-button class="pop-close" @click="handleClose2">关闭</el-button>
        <el-button
          class="pop-save pop-right"
          @click="importEmployeeMedicalInsuranceRecords"
          >导入</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="编辑医保购买或台账"
      :visible.sync="dialogVisible"
      width="1200px"
      :before-close="handleClose"
      top="3vh"
    >
      <div class="dialog-main">
        <div class="line-box">
          <div class="from-box">
            <div class="name">员工编码:</div>
            <el-input
              v-model="parameter.EmployeeCode"
              disabled
              placeholder="员工编码"
            ></el-input>
          </div>
          <div class="from-box">
            <div class="name">拼音姓名:</div>
            <el-input
              v-model="parameter.NameinEnglish"
              disabled
              placeholder="拼音姓名"
            ></el-input>
          </div>
          <div class="from-box">
            <div class="name">性别:</div>
            <el-select
              v-model="parameter.Gender"
              placeholder="性别"
              filterable
              clearable
              disabled
              class="card-select"
            >
              <el-option
                v-for="item in options3"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="line-box">
          <div class="from-box">
            <div class="name">国籍:</div>
            <el-input
              v-model="parameter.NationalityName"
              disabled
              placeholder="拼音姓名"
            ></el-input>
          </div>
          <div class="from-box">
            <div class="name">SPONSOR:</div>
            <el-select
              v-model="parameter.SPONSOR"
              placeholder="SPONSOR"
              filterable
              clearable
              disabled
              class="card-select"
            >
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="from-box">
            <div class="name">海关入境号:</div>
            <el-input
              v-model="parameter.BorderNumber"
              disabled
              placeholder="海关入境号"
            ></el-input>
          </div>
        </div>
        <div class="line-box">
          <div class="from-box">
            <div class="name">暂住证号:</div>
            <el-input
              v-model="parameter.IQAMAID"
              placeholder="暂住证号"
              disabled
            ></el-input>
          </div>
          <div class="from-box">
            <div class="name">暂住证日期:</div>
            <el-input
              v-model="parameter.IQAMAValidDate"
              placeholder="暂住证号"
              disabled
            ></el-input>
          </div>
          <div class="from-box">
            <div class="name">申请日期:</div>
            <el-input
              v-model="parameter.DateofApplication"
              placeholder="暂住证号"
              disabled
            ></el-input>
          </div>
        </div>

        <div class="line-box">
          <div class="from-box">
            <div class="name">医保等级:</div>
            <el-select
              v-model="parameter.ClassID"
              placeholder="医保等级"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in classList"
                :key="item.ID"
                :label="item.NameinChinese"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </div>
          <div class="from-box">
            <div class="name">原因:</div>
            <el-input
              v-model="parameter.ReasontoAdjustment"
              placeholder="原因"
            ></el-input>
          </div>
          <div class="from-box">
            <div class="name">拟调整等级:</div>
            <el-select
              v-model="parameter.NewClassLevel"
              placeholder="医保等级"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in classList"
                :key="item.ID"
                :label="item.NameinChinese"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="line-box">
          <div class="from-box">
            <div class="name">医保公司:</div>
            <el-select
              v-model="parameter.InsuranceCompany"
              placeholder="医保公司"
              filterable
              clearable
              class="card-select"
            >
              <el-option
                v-for="item in governList"
                :key="item.ID"
                :label="item.NameinChinese"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </div>
          <div class="from-box">
            <div class="name">医保成本:</div>
            <el-input
              v-model="parameter.TicketCost"
              placeholder="医保成本"
            ></el-input>
          </div>
          <div class="from-box">
            <div class="name">分割项目费用:</div>
            <el-input
              v-model="parameter.DividingAmount"
              placeholder="医保成本"
            ></el-input>
          </div>
        </div>
        <div class="line-box">
          <div class="from-box">
            <div class="name">收益差额:</div>
            <el-input
              v-model="parameter.IncomeSAR"
              placeholder="收益差额"
            ></el-input>
          </div>
          <div class="from-box">
            <div class="name">付款日期:</div>
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="parameter.PaymentDate"
              type="date"
              placeholder="付款日期"
            >
            </el-date-picker>
          </div>
          <div class="from-box">
            <div class="name">退保费用:</div>
            <el-input
              v-model="parameter.RefundofCancellation"
              placeholder="退保费用"
            ></el-input>
          </div>
        </div>
        <div class="line-box">
          <div class="from-box from-box-all">
            <div class="name">备注:</div>
            <el-input v-model="parameter.Remark" placeholder="备注"></el-input>
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <el-button class="pop-close" @click="handleClose">取消</el-button
        ><el-button class="pop-save" @click="save">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { reproduce } from "@/utils/recursion";
import {
  getProjectsTree,
  getProjectAll,
  getCompanyAll,
  uploadAttachment,
} from "@/api/user";
import {
  getEmployeeMedicalInsuranceRecords,
  getEmployeeMedicalInsuranceRecordsChildren,
  deleteEmployeeMedicalInsuranceRecordsChildren,
  getEmployeeMedicalInsuranceRecordsTemplate,
  importEmployeeMedicalInsuranceRecords,
  exportEmployeeMedicalInsuranceRecords,
  getEmployeeMedicalInsuranceRecordsItem,
  editEmployeeMedicalInsuranceRecords,
  getEmployeeMedicalInsuranceClassLevelsAll,
  getGovernmentSystemDicAll,
} from "@/api/table";
export default {
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      dialogVisible: false,
      dialogVisible2: false,
      dialogType: 1, //1新建2、编辑3、查看
      powers: {
        add: false,
        update: false,
        delete: false,
        download: false,
        import: false,
        export: false,
        print: false,
        examine: false,
        upload: false,
        resetting: false,
      },
      myProjectId: null,
      downloadUrl: null,
      name2: null,
      treeObj: {},
      total: null,
      page: 1,
      size: 10,
      loading: true,
      sponsor: null,
      companyID: null,
      projectID: null,
      evacuationDate: null,
      userCode: null,
      iqamaCode: null,
      borderNumber: null,
      projectList: [],
      companyList: [],
      options1: [],
      classList: [],
      governList: [],
      options2: [
        {
          label: "SNEI",
          value: 1,
        },
        {
          label: "SEG",
          value: 2,
        },
        {
          label: "其它",
          value: 3,
        },
      ],
      options3: [
        {
          value: 1,
          label: "男",
        },
        {
          value: 2,
          label: "女",
        },
        {
          value: 3,
          label: "未知",
        },
      ],
      parameter: {},
    };
  },
  filters: {
    timeFormater(value) {
      if (!value) {
        return;
      }
      value = value.substring(0, 10);
      return value;
    },
    labelFormater(id, data) {
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.label : null;
    },
  },
  computed: {},
  methods: {
    handleClose() {
      this.dialogVisible = false;
      this.parameter = {};
    },
    save() {
      editEmployeeMedicalInsuranceRecords(this.parameter).then((res) => {
        if (res.status === 200) {
          this.$message.success("编辑成功");
          this.handleClose();
        }
      });
    },
    handleClose2() {
      this.dialogVisible2 = false;
      this.ParentIds = [];
      this.getTree();
    },

    edit(row) {
      this.dialogVisible = true;

      console.log("row", row);
      getEmployeeMedicalInsuranceRecordsItem({ key: row.KeyID }).then((res) => {
        const response = res.response;
        if (res.status === 200) {
          this.parameter = response;
        }
      });
    },

    doLayout() {
      this.$nextTick(() => {
        this.$refs.doLayout.doLayout();
      });
    },
    //获取项目部列表
    getEmployeeMedicalInsuranceRecords(type) {
      if (type) {
        this.page = type;
      }
      this.loading = true;

      let startDate = null;
      let endDate = null;

      if (this.evacuationDate) {
        startDate = this.evacuationDate[0];
        endDate = this.evacuationDate[1];
      }

      const data = {
        page: this.page,
        size: this.size,
        sponsor: this.sponsor,
        companyID: this.companyID,
        projectID: this.projectID,
        userCode: this.userCode,
        iqamaCode: this.iqamaCode,
        borderNumber: this.borderNumber,
        startDate,
        endDate,
      };
      getEmployeeMedicalInsuranceRecords(data).then((res) => {
        if (res.status === 200) {
          this.tableData = res.response.data;
          this.total = res.response.dataCount;
          // this.doLayout();
        }
        this.loading = false;
      });
    },
    resetLazyTree() {
      this.$set(this.$refs.doLayout.store.states, "lazyTreeNodeMap", {});
    },
    //获取子选项
    load(tree, treeNode, resolve) {
      this.treeObj[tree.KeyID] = { tree, treeNode, resolve };
      getEmployeeMedicalInsuranceRecordsChildren({ key: tree.KeyID }).then(
        (res) => {
          if (res.status === 200) {
            if (res.response.length === 0) {
              this.resetLazyTree();
            }
            resolve(res.response);
          }
        }
      );
    },
    //删除
    deleteEmployeeMedicalInsuranceRecordsChildren(row) {
      let data = { key: row.KeyID };

      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteEmployeeMedicalInsuranceRecordsChildren(data).then((res) => {
          //
          if (res.status === 200) {
            for (let i in this.treeObj) {
              const { tree, treeNode, resolve } = this.treeObj[i];
              this.load(tree, treeNode, resolve);
            }
            this.getEmployeeMedicalInsuranceRecords();
            this.$nextTick(() => {
              this.$message.success("删除成功");
            });
          }
        });
      });
    },

    getTree() {
      getProjectsTree().then((res) => {
        if (res.status === 200) {
          this.tree = reproduce(res.response, [], false);
        }
      });
    },
    getButtonPower() {
      if (!this.$store.state.userPower) {
        this.$store.commit(
          "changeUserPower",
          JSON.parse(localStorage.getItem("userPower"))
        );
      }

      const item = this.$store.state.userPower.find((item) => {
        return item.Name === this.$route.meta.title;
      });
      const actions = item.Actions;

      actions.forEach((element) => {
        switch (element.Id) {
          case 1: //增加
            this.powers.add = true;
            break;
          case 2: //删除
            this.powers.delete = true;
            break;
          case 3: //修改
            this.powers.update = true;
            break;
          case 5: //下载
            this.powers.download = true;
            break;
          case 6: //导入
            this.powers.import = true;
            break;
          case 7: //导出
            this.powers.export = true;
            break;
          case 8: //打印
            this.powers.print = true;
            break;
          case 9: //审批
            this.powers.examine = true;
            break;
          case 10: //上传
            this.powers.upload = true;
            break;
          case 11: //重置
            this.powers.resetting = true;
            break;
        }
      });
    },
    //导入
    importEmployeeMedicalInsuranceRecords() {
      const data = {
        Path: this.uploadUrl,
        KeyID: this.KeyID,
      };
      importEmployeeMedicalInsuranceRecords(data).then((res) => {
        if (res.status === 200) {
          this.$message.success("导入成功");
          this.handleClose2();
          this.getEmployeeMedicalInsuranceRecords();
        }
      });
    },

    //下载附件
    getDownloadUrl(url) {
      location.href = url;
    },
    uploadAttachment(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix1 = img === "xls";
      const suffix2 = img === "xlsx";
      if (!suffix1 && !suffix2) {
        this.$message.error("只能上传后缀为xls、xlsx的文件!");
        return;
      }

      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.name2 = data.name;
          this.uploadUrl = res.response;
          console.log("this.name2", this.name2);
        }
      });
    },

    //导出
    exportEmployeeMedicalInsuranceRecords() {
      let startDate = null;
      let endDate = null;

      if (this.evacuationDate) {
        startDate = this.evacuationDate[0];
        endDate = this.evacuationDate[1];
      }

      const data = {
        page: this.page,
        size: this.size,
        sponsor: this.sponsor,
        companyID: this.companyID,
        projectID: this.projectID,
        userCode: this.userCode,
        iqamaCode: this.iqamaCode,
        borderNumber: this.borderNumber,
        startDate,
        endDate,
      };
      exportEmployeeMedicalInsuranceRecords(data).then((res) => {
        if (res.status === 200) {
          let url = res.response;
          let charIndex = url.indexOf("Download");
          url = url.substr(charIndex, url.length);
          url = url.replace(/\\/g, "/");
          location.href = "https://hrms.baishulai.com/" + url;
        }
      });
    },
  },

  created() {
    this.getEmployeeMedicalInsuranceRecords();
    this.getButtonPower();
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    getEmployeeMedicalInsuranceRecordsTemplate().then((res) => {
      if (res.status === 200) {
        this.downloadUrl = "https://hrms.baishulai.com/" + res.response;
      }
    });

    getEmployeeMedicalInsuranceClassLevelsAll().then((res) => {
      if (res.status === 200) {
        this.classList = res.response;
      }
    });

    getGovernmentSystemDicAll({ typeID: 4 }).then((res) => {
      if (res.status === 200) {
        this.governList = res.response;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-color: #f7f8fa;
  .page-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .page-title {
      font-size: 18px;
      color: #000000;
    }
    .breadcrumb-active {
      color: #303133;
      font-weight: bolder;
      cursor: pointer;
      .fa {
        margin-right: 5px;
      }
    }
  }
  .card-box {
    width: 100%;
    // min-height: calc(100% - 48px);
    // height: calc(100% - 48px);
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    margin-top: 24px;
    box-sizing: border-box;
    padding: 0 0 24px 0;
    .screen-box {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      padding: 0 12px;
      .el-input {
        width: 150px;
        margin-right: 24px;
      }
      .el-select {
        width: 150px;
        margin-right: 24px;
      }
      .el-button {
        margin-left: 24px;
      }
      .senior {
        position: absolute;
        width: 100%;
        top: 40px;
        left: 0;
        background: #fefefe;
        border: 1px solid #eae8e8;
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: rgb(234, 232, 232);
        box-sizing: border-box;
        z-index: 999;
        padding: 0 24px 24px 24px;
        border-top: 0;
        box-shadow: 0 7px 18px -12px #bdc0bb;
        .from-boxs {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .from-box {
            margin-top: 24px;
            .name {
              margin-bottom: 10px;
            }
            .el-select {
              width: 150px;
            }
            .el-input {
              width: 150px;
            }
          }
        }
        .buttons {
          display: flex;
          justify-content: flex-end;
          margin-top: 24px;
        }
      }
      .input-box {
        display: flex;
        align-items: center;
        height: 34px;
        .title-box {
          padding: 0 12px;
          border: 1px solid #d2d6de;
          line-height: 32px;
          border-right: none;
          color: #555;
          font-size: 14px;
        }
      }
    }
    .table-box {
      width: 100%;
      // height: calc(100% - 65px);
      .el-table {
        margin-top: 24px;
        // height: calc(100% - 56px);
        min-height: 500px;
        box-sizing: border-box;
      }
      .el-pagination {
        margin-top: 24px;
      }
      .table-color {
        color: #0076ff;
      }
      .buttons {
        display: flex;
      }
    }
  }
}
.dialog-main {
  border-bottom: 1px solid #f4f4f4;
  padding: 12px 24px 0 24px;
  width: 100%;
  box-sizing: border-box;
  .line-box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .from-box {
      display: flex;
      align-items: center;
      width: 32%;
      margin-bottom: 24px;
      .name {
        width: 100px;
        font-size: 16px;
        flex-shrink: 0;
      }
      .el-cascader {
        width: 100%;
      }
      .el-input {
        width: 100%;
      }
      .el-select {
        width: 100%;
      }
    }
    .from-box-all {
      width: 100%;
    }
  }
}
.dialog-footer {
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .el-button {
    height: 34px;
    padding: 0 20px;
    margin-right: 12px;
    margin-left: 0;
  }
}
.text-box {
  display: flex;
  color: #000000;
  .text-title {
    margin-right: 24px;
  }
  .text-name {
    color: #0076ff;
    cursor: pointer;
  }
}
.upload-box2 {
  display: flex;
  height: 34px;
  margin-top: 24px;
  .left {
    width: 100%;
    border: 1px solid #d2d6de;
    border-right: none;
    .name {
      display: flex;
      align-items: center;
      height: 34px;
      color: #555;
      font-size: 14px;
      text-indent: 2px;
    }
  }
  .btn-up {
    padding: 0;
    margin: 0;
    border: none;
    width: 90px;
    flex-shrink: 0;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #3c8dbc;
    border: 1px solid #367fa9;
    color: #fff;
    font-size: 14px;
    .icon {
      margin-right: 5px;
    }
  }
  .disabled {
    cursor: no-drop;
  }
  .btn-up:hover {
    border-color: #204d74;
    background-color: #367fa9;
  }
}
.btn-add {
  margin-right: 24px;
}
.dialog-footer2 {
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .el-button {
    height: 34px;
    padding: 0 20px;
    margin-right: 12px;
    margin-left: 0;
  }
}
</style>
<style scoped lang='scss'>
// el-table表格对齐
.el-table ::v-deep .el-table__row:not([class*="el-table__row--level-"]) {
  td:nth-child(2) {
    padding-left: 24px !important; //一级数据无Child缩进
  }
}
.el-table ::v-deep .el-table__placeholder {
  margin-left: 3px; //子节点无Child缩进
}
</style>

<style lang="scss" scoped>
::v-deep .el-table {
  width: 100%;
  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }
  .el-table__body,
  .el-table__footer,
  .el-table__header {
    table-layout: auto;
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
  .el-table__header {
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
}
</style>
<style scoped>
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 0;
  overflow: auto;
  border-top: 1px solid #f4f4f4;
}
.el-dialog__wrapper >>> .my-label {
  background: #f5f7fa !important;
}
.el-textarea >>> .el-textarea__inner {
  min-height: 100px !important;
}
.el-table >>> .el-table__placeholder {
  width: 23px;
}
.table-box >>> .el-table__empty-block {
  width: 100% !important;
}
.el-table >>> th.el-table__cell > .cell {
  font-weight: bolder;
  font-size: 12px;
  text-align: center;
  font-family: "Microsoft YaHei";
}

.el-table >>> th.el-table__cell {
  padding: 6px 0;
}

.el-table >>> .el-table__body .cell {
  text-align: center;
  font-size: 12px;
  color: #333;
}
.el-table >>> .el-table__cell {
  padding: 6px 0;
}
.approval {
  display: inline;
  padding: 2px 5px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  font-size: 12px;
}
.label-success {
  background-color: #00a65a;
}
.label-warning {
  background-color: #f39c12;
}
.fa {
  margin-right: 10px;
  color: #3c8dbc;
  cursor: pointer;
}
.fa:hover {
  color: #72afd2;
}
.screen-box >>> .el-input__inner {
  height: 34px;
  border-color: #d2d6de;
  border-radius: 0;
}
.screen-box >>> .el-input__inner:focus {
  height: 34px;
  border-color: #61a2c9;
  border-radius: 0;
}
.card-upload >>> .el-upload-list {
  display: none;
}
.dialog >>> .el-dialog__body {
  padding: 15px 15px 0 15px;
}
.tableData2 >>> .el-table__empty-block {
  width: 100% !important;
}
.card-select >>> .el-input__icon {
  line-height: 34px;
}
.downloadd {
  color: #519fff;
  cursor: pointer;
}
.screen-input-box {
  margin-top: 24px;
}
.screen-input-box >>> .el-date-editor {
  margin-right: 24px;
}
.btn-pre {
  margin-top: 24px;
}
</style>
